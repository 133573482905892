import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import Editable from "ui321/single/Editable.js";
import Field from "ui321/fields/Field.js";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder.js";

function FullView(props) {
  const {t} = useTranslation("Sqattributes");
  return (
    <Editable>
      <SqattributeButtons />
      <Folder title={t("Overview", {context: "folderTitle"})}>
      <Field name="parent" />
        <Field name="name" />
        <Field name="accdate" />
        <Field name="val_amount" />
        <Field name="val_time" />
        <Field name="val_text" />
        <Field name="endaccdate" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
      </Folder>
      <RecordGroupsFolder />
      {/* <Folder title={t("Groups again", {context: "folderTitle"})} folded> //groups subpanel
        <Field name="record_id-securitygroupsrecords" />
      </Folder> */}
    </Editable>
  );
}

function SqattributeButtons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
      <ButtonDelete />
    </Segment>
  );
}

export default FullView
