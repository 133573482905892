import React from "react";
import {useTranslation} from "react-i18next";
import {Route, Switch} from "react-router-dom";
import ResourceRoutes from "ui321/ResourceRoutes.js";
import Home from "./Home.js";
import PageNotImplemented from "./PageNotImplemented.js";
import Profile from "./Profile.js";

const appTitle = "ui321-waterman-demo"; //TODO: appTitle is defined in App.js

function AppRoutes(props) {
  const {t} = useTranslation("app");
  return (
    <Switch location={props.location}>
      <Route exact path="/" render={props =>
        <Home title={
          <span>{t("Welcome to")} <q>{appTitle}</q></span>} />} />
      <Route path="/myprofile" component={Profile} />
      <Route path="/TODO*" component={PageNotImplemented} />
      <ResourceRoutes />
    </Switch>
  );
}

export default AppRoutes
