import ACLRoles from "./resources/ACLRoles/ACLRoles.js";
import ACLRolesUsers from "./resources/ACLRolesUsers/ACLRolesUsers.js";
import PAccounts from "./resources/PAccounts/PAccounts.js";
import Sqattributes from "./resources/Sqattributes/Sqattributes.js";
import SecurityGroups from "./resources/SecurityGroups/SecurityGroups.js";
import SecurityGroupsRecords, {SecurityGroups_Modules} from "./resources/SecurityGroupsRecords/SecurityGroupsRecords.js";
import Users from "./resources/Users/Users.js";

const AppResources = {
  [ACLRoles.resourceType]: ACLRoles,
  [ACLRolesUsers.resourceType]: ACLRolesUsers,
  [PAccounts.resourceType]: PAccounts,
  [SecurityGroups.resourceType]: SecurityGroups,
  [SecurityGroupsRecords.resourceType]: SecurityGroupsRecords,
  ...SecurityGroups_Modules,
  [Users.resourceType]: Users,
  [Sqattributes.resourceType]: Sqattributes
}

export default AppResources
