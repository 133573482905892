import i18n from "i18n.js";
import en_i18n from "./i18n/en.PAccounts.json";
import ru_i18n from "./i18n/ru.PAccounts.json";
import FullView from "./FullView.js";

const resourceType = "PAccounts";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const PAccounts = {
  resourceType,
  FullView,
  titleField: "paccount",
  defaultSort: [["paccount", "desc"]],
  fieldNames: [
    "fio",
    "paccount",
    "flat",
    "residents_qty",
    "absents_qty",
    "owners_qty",
    "has_spipe",
    "has_wpipe",
    "accdate",
    "endaccdate",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id"
  ],
  itemViewFields: {
    // "meta": [
    // ],
    "description": [
        "fio",
        "paccount"
    ],
    "extra": [
        "date_entered",
        "created_by",
        "date_modified",
        "modified_user_id",
    ]
  },
  creationAccess: true,
};

export default PAccounts
