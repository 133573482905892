import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import Editable from "ui321/single/Editable.js";
import Field from "ui321/fields/Field.js";
import AttributedField from "./Field";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder.js";
import ToManyRelationshipAll from "ui321/fields/ToManyRelationshipAll";
import SqattributesDetailLine from "./SqattributesDetailLine";

function ParentFullView(props) {
  // const {t} = useTranslation(props.resourceType);
  return (
    <>
      <Editable>
      <Buttons />
      <Folder title="Таблица изменений">
      <Field
          name={props.name}
          as={ToManyRelationshipAll.componentName}
          fieldProps={{
            sorting: "accdate",
            template: SqattributesDetailLine,
            // disableRemoving: true,
            emptinessLabel: "Нет значений",
          }}
        />
        </Folder> 
        </Editable>
    </>
    );
}

function Buttons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
    </Segment>
  );
}

export default ParentFullView
