import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import Editable from "ui321/single/Editable.js";
import Field, { Break } from "ui321/fields/Field.js";
import AttributedField from "../Sqattributes/Field";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder.js";
import ToManyRelationshipAll from "ui321/fields/ToManyRelationshipAll";
import SqattributesDetailLine from "../Sqattributes/SqattributesDetailLine";

function FullView(props) {
  const {t} = useTranslation("PAccounts");
  return (
    <Editable>
      <PAccountButtons />
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="fio" />
        <Field name="paccount" />
        <Field name="flat" />
        {/* <Field name="has_spipe" />
        <Field name="has_wpipe" /> */}
        <Break/>
        <Field name="accdate" />
        <Field name="endaccdate" />
      </Folder>
      <Folder title={"Атрибуты"}>
        <AttributedField name="residents_qty" historyName="residents_history" />
        <AttributedField name="absents_qty" historyName="absents_history" />
        <AttributedField name="owners_qty" historyName="owners_history" />
      </Folder>      
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
      </Folder>
      <Folder title={"Жильцы"}>
        <Field name="residents_history" />
      </Folder>
      <RecordGroupsFolder />
      {/* <Folder title={t("Groups again", {context: "folderTitle"})} folded> //groups subpanel
        <Field name="record_id-securitygroupsrecords" />
      </Folder> */}
    </Editable>
  );
}

function PAccountButtons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
      <ButtonDelete />
    </Segment>
  );
}

export default FullView
