import React from "react";
import {useTranslation} from "react-i18next";
import {BrowserRouter, Link, Route} from "react-router-dom";
import {Button, Icon, Menu} from "semantic-ui-react";
import Connection, {ConnectionContext} from "ui321/Connection.js";
import PageLayout from "ui321/PageLayout.js";
import ResourceTypeList from "ui321/ResourceTypeList.js";
import ResourcesMenu, { addLabelsFromMenu } from "ui321/ResourcesMenu.js";
import ResourceSettings, {ResourceSettingsContext} from "ui321/ResourceSettings.js";
import Loading from "ui321/Loading.js";
import {clearMetadataCache} from "ui321/ResourceMetadata.js";
import {ApiConfigContext, jsonHeaders} from "ui321/json/Request.js";
import "./App.css";
import AppResources from "./AppResources.js";
import AppRoutes from "./AppRoutes.js";
import {SecurityGroups_Modules} from "./resources/SecurityGroupsRecords/SecurityGroupsRecords.js";

function appLinks(t) {
  return [
  { path: "/PAccounts", resource: "PAccounts", label: t("PAccounts", {context: "menuItem"}), group: [t("Sales", {context: "menuItem"})] },
  { path: "/Users", resource: "Users", label: t("Users", {context: "menuItem"}), group: [t("Administration", {context: "menuItem"})] },
  { path: "/ACLRoles", resource: "ACLRoles", label: t("ACLRoles", {context: "menuItem"}), group: [t("Administration", {context: "menuItem"})] },
  { path: "/SecurityGroups", resource: "SecurityGroups", label: t("SecurityGroups", {context: "menuItem"}), group: [t("Administration", {context: "menuItem"})] },
  { path: "/Sqattributes", resource: "Sqattributes", label: t("Sqattributes", {context: "menuItem"}), group: [t("Sales", {context: "menuItem"})] },  
  ];
}

function AppMenuResources(t) {
  return addLabelsFromMenu(appLinks(t), AppResources);
}

function isHiddenResource(resourceType) {
  return resourceType === "ACLRolesUsers" || SecurityGroups_Modules[resourceType];
}

const appTitle = "ui321-waterman-demo";
const apiConfig = {
  urlPrefix: "/v1",
  init: {
    headers: jsonHeaders,
  },
};

function App() {
  const {t} = useTranslation("app");
  const appLinksT = appLinks(t);
  return (
  // <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <ApiConfigContext.Provider value={apiConfig}>
      <BrowserRouter>
      <Connection loginTitle={appTitle}>
        { connection =>
        <ConnectionContext.Provider value={connection}>
            <>
            <ResourceSettings appSettings={AppMenuResources(t)}>
            { settings =>
            <ResourceSettingsContext.Provider value={settings}>
              <Route render={pageRoute =>
              <PageLayout
                pagePath={pageRoute.location.pathname}
                sideMenu={
              <ResourceTypeList>
                { resources =>
                  <ResourcesMenu
                    homeLinkText={appTitle}
                    appLinks={appLinksT}
                    resources={resources.filter(r => !isHiddenResource(r.name)).map(r => ({
                      name: r.name,
                      label: t(r.name, {context: "menuItem"}),
                      group: appLinksT && appLinksT.length ? [t("Other", {context: "menuItem"})] : [],
                    }))}
                  /> }
              </ResourceTypeList>
              }
                header={layout =>
                  <Menu secondary>
                    <Menu.Item fitted>
                      <layout.MenuButton />
                    </Menu.Item>
                    <Menu.Item position="right" fitted>
                      <Link to="/myprofile" className="username ui item fitted">
                        {connection.auth ? connection.auth.username : connection.authBackup.username}
                      </Link>
                      { process.env.NODE_ENV === "development" &&
                      <>
                      &nbsp;
                      <Button type="button"
                        className="refresh link item"
                        onClick={() => {
                          clearMetadataCache(sessionStorage);
                          window.location.reload();
                        }}
                        title={"Clear cache and reload"}>
                        <Icon name="refresh" fitted />
                      </Button>
                      </>
                      }
                      &nbsp;
                      <Button type="button"
                        onClick={connection.close} className="logout link item"
                        title={t("Log out")}
                        loading={!connection.authBackup} disabled={!connection.authBackup}>
                        <Icon name="power" color="red" fitted />
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
                content={AppRoutes}
              />
              }/>
            </ResourceSettingsContext.Provider> }
            </ResourceSettings>
            </>
        </ConnectionContext.Provider> }
      </Connection>
      </BrowserRouter>
      </ApiConfigContext.Provider>
    </React.Suspense>
  // </React.StrictMode>
  );
}

export default App
