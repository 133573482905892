/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import i18n from "i18n.js";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Breadcrumb, Modal, Icon} from "semantic-ui-react";
import ResourceTypeLabel from "ui321/ResourceTypeLabel.js";
import CollectionNavigation, {defaultReadResponseState} from "ui321/collection/CollectionNavigation.js";
import ColumnHead from "ui321/collection/ColumnHead.js";
import DataTable, {dataRowSpans} from "ui321/collection/DataTable.js";
import FieldCell from "ui321/collection/FieldCell.js";
import Field from "ui321/fields/Field.js";
import {UuidString} from "ui321/fields/Uuid.js";
import {CollectionResponse} from "ui321/json-api/ResourceCollectionNavigation.js";
import ResourceMetadata from "ui321/ResourceMetadata.js";
import SingleResource, {ResourceContext, useResourceRefetch} from "ui321/single/SingleResource.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import ParentFullView from "./ParentFullView";

function ResourceHistoryButton(props) {
  const resource = React.useContext(ResourceContext);
  return (
    <HistoryButton resourceType={resource.type} id={resource.id} name={props.name}/>
  );
}

function HistoryButton(props) {
  const parentType = props.resourceType;
  const parentId = props.id;
  const name = props.name
  const refetch = useResourceRefetch()  
  return (
    <HistoryModalState
      resourceType={parentType}
      id={parentId}
      name={name}
      refetchParent={refetch}
      trigger={props => (
        <Link className="ui"
          to={`.`}
          onClick={event => {
            event.preventDefault();
            props.setOpened(true);
          }}
        >
          &nbsp; &nbsp;
          <Icon name="history" fitted />
        </Link>
      )} />
  );
}

function HistoryPage(props) {  
  return (
    <>
      <History
        resourceType={props.resourceType}
        id={ props.id}
        name={props.name}
      />
    </>
  );
}

function HistoryModalState(props) {
  const [opened, setOpened] = React.useState(false);
  return (
    <>
      { props.trigger &&
        React.createElement(props.trigger, {setOpened: setOpened}) }
      <HistoryModal {...props}
        opened={opened}
        setOpened={setOpened}
      />
    </>
  );
}

function HistoryModal(props) {
  const {t} = useTranslation(props.resourceType);
  return (
    <Modal className="history-modal"
      open={props.opened}
      onClose={() => props.setOpened(false)}
      closeIcon closeOnDimmerClick={false}
      size="fullscreen"
      centered={false}
    >
      <Modal.Header>{"История изменений атрибута " + t(props.name, {context: "fieldLabel"})}</Modal.Header>
      <Modal.Content>
        <History {...props} />
      </Modal.Content>
    </Modal>
  );
}

function History(props) {
  const parentType = props.resourceType;
  return (
    <SingleResource
      key={props.id}
      resourceType={props.resourceType}
      id={props.id}
      editing
      fullView={ParentFullView}
      viewProps={{name: props.name}}
      constData={props.constData}
      defaultData={props.defaultData}
      onEditingCancel={() => props.setOpened(false)}
      onUpdate={() => {
        props.refetchParent()
        props.setOpened(false)}
      }
  />
  );
}

const sortableColumns = [
  "date_created",
];

export {HistoryButton, ResourceHistoryButton, HistoryPage};
export default History
