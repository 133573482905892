import i18n from "i18n.js";
import en_i18n from "./i18n/en.SecurityGroupsRecords.json";
import ru_i18n from "./i18n/ru.SecurityGroupsRecords.json";
import FullView from "./FullView.js";

const resourceType = "SecurityGroupsRecords";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);
i18n.addResourceBundle("en", "SecurityGroups_PAccounts", en_i18n);
i18n.addResourceBundle("ru", "SecurityGroups_PAccounts", ru_i18n);

const SecurityGroupsRecords = {
  resourceType,
  FullView,
  titleField: "id",
  fieldNames: [
    "securitygroup_id",
    "record_id",
    "date_modified",
  ],
  reverseRelationships: {
  },
};

const SecurityGroups_Modules = {
  "SecurityGroups_PAccounts": SecurityGroupsRecords,
  "SecurityGroups_Sqattributes": SecurityGroupsRecords,
};

export {SecurityGroups_Modules};
export default SecurityGroupsRecords
