/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Leon Nikitin <nlv@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Dropdown, Icon, Input, Message} from "semantic-ui-react";
import BoolEnum from "../../../ui321/fields/BoolEnum.js";
import DateField from "../../../ui321/fields/DateField.js";
import ToOneRelationship, {ToOneRelationshipLink} from "../../../ui321/fields/ToOneRelationship.js";
import ToManyRelationshipAll from "../../../ui321/fields/ToManyRelationshipAll.js";
import ToManyRelationshipNav from "../../../ui321/fields/ToManyRelationshipNav.js";
import ToManyRelationshipTitles from "../../../ui321/fields/ToManyRelationshipTitles.js";
import EnumToOneRelationship from "../../../ui321/fields/EnumToOneRelationship.js";
import {ResourceContext, ResourceStateContext,
  ResourceEditorContext, ResourceEditorDispatchContext, SingleResource} from "ui321/single/SingleResource.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import {fieldStateSchema, StateFieldButton, transitionEnum} from "ui321/biz/StateSchema.js";
import { ResourceHistoryButton } from "./History.js";

// FIXME: Скопирована с ui321. Надо заиспользовать аккуратно


function Field(props) {
  const fieldName = props.name;
  const historyName = props.historyName;
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  const editedValue = (editor.patch.relationships || {})[fieldName] !== undefined
      ? editor.patch.relationships[fieldName]
      : (editor.patch.attributes || {})[fieldName];
  const fieldErrors = (editor.errors || {})[fieldName];
  return (
    <MemoField
      {...props.fieldProps}
      className={props.className}
      isEditing={isEditing}
      name={fieldName}
      historyName={historyName}
      editedValue={editedValue}
      errors={fieldErrors}
      renderLabel={props.renderLabel}
      as={props.as}
      render={props.render}
    />
  );
}

const MemoField = React.memo(EditedField);

function SqattributesCall(props) {
  return (
    <ResourceHistoryButton {...props}/>
  )
}

function EditedField(props) {
  const fieldName = props.name;
  const resource = React.useContext(ResourceContext);
  const resourceState = React.useContext(ResourceStateContext);
  const editorDispatch = React.useContext(ResourceEditorDispatchContext);
  const {t} = useTranslation(resource.type);
  if (!resource) {
    console.warn('No resource context for field ' + fieldName);
    return null;
  }
  if (!resource.meta) {
    console.warn(`No resource metadata for field '${fieldName}'. Resource:`, resource);
    return null;
  }
  const isNew = !resource.id;
  const labelText = t(fieldName, {context: "fieldLabel"});
  const attrSchemaRaw = JsonApiSchema.findAttributeSchema(fieldName, resource.meta.jsonSchema);
  const attrSchema = JsonApiSchema.fromNullable(attrSchemaRaw);
  const relSchema = JsonApiSchema.findRelationshipSchema(fieldName, resource.meta.jsonSchema);
  const fieldSchema = relSchema || attrSchema || {};
  const relatedResourceType = relSchema ? JsonApiSchema.getRelationshipResourceType(relSchema) : null;
  const isModified = props.editedValue !== undefined;
  const fieldValue = isModified
    ? props.editedValue
    : (fieldName === "id"
      ? resource.id
      : (relSchema ? (resource.relationships || {})[fieldName] : (resource.attributes || {})[fieldName]));
  const valueBefore = fieldName === "id"
      ? resource.id
      : (relSchema ? (resource.relationships || {})[fieldName] : (resource.attributes || {})[fieldName]);
  const isEditing = props.isEditing && !JsonApiSchema.isReadOnly(fieldName, resource.meta.jsonSchema);
  const isRequired = ((((((resource || {}).meta || {}).jsonSchema || {}).properties || {}).attributes || {}).required || []).indexOf(fieldName) !== -1
    || ((((((resource || {}).meta || {}).jsonSchema || {}).properties || {}).relationships || {}).required || []).indexOf(fieldName) !== -1;
  const isEmpty = (!fieldValue && typeof fieldValue !== "number") || (typeof fieldValue === "object" && !fieldValue.data);
  const stateFieldErrors = (resourceState.errorMessages || [])
    .filter(err => typeof err === "object"
      && err.fieldName === fieldName
      && !!err.message
      && (props.errors || []).indexOf(err.message) === -1
      && JSON.stringify(err.fieldValue) === JSON.stringify(fieldValue)
    )
    .map(err => err.message)
  const fieldErrors = (props.errors || []).concat(stateFieldErrors);
  const elemProps = {
    className: "resource-field"
      + (props.className ? " " + props.className : "")
      + (isEditing ? " editing" : " reading")
      + (isRequired ? " required" : "")
      + (isEmpty ? " empty-value" : ""),
    "data-field": fieldName,
    "data-type": fieldSchema.type || typeof fieldValue,
  };
  const label = props.renderLabel ? React.createElement(props.renderLabel, {
    labelText: labelText,
    isEditing: isEditing,
  }) : (
    <label>{labelText}</label>
  );

  if (props.render) {
    return React.createElement(props.render, {
      fieldValue: fieldValue,
      fieldName: fieldName,
      isEditing: isEditing,
      elemProps: elemProps,
      label: label,
      fieldErrors: fieldErrors,
    });
  }

  if (relSchema && ((relSchema.properties || {}).data || {}).type === "array") {
    //TODO: 'as' prop presents in SingleForm template, but not in other places
    //TODO: if template then ToManyRelationshipAll else ToManyRelationshipTitles (but Titles does not load related resources for now)
    if ((props.as === ToManyRelationshipTitles.componentName || (!props.as && fieldValue !== undefined))
      && relatedResourceType && Array.isArray(relatedResourceType) && relatedResourceType.length === 1) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <ToManyRelationshipTitles
              name={fieldName}
              value={(fieldValue || {}).data}
              resourceType={relatedResourceType[0]}
              isEditing={isEditing}
              onChange={(name, value) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: value});
              }}
            />
            { typeof fieldValue === "string" && fieldValue }{/* recover for error case */}
          </div>
          {/* <p>a1</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    if (props.as === ToManyRelationshipAll.componentName) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <ToManyRelationshipAll
              name={fieldName}
              editedValue={props.editedValue}
              isEditing={isEditing}
              template={props.template}
              toBeDeletedTemplate={props.toBeDeletedTemplate}
              toBeDeletedField={props.toBeDeletedField}
              sorting={props.sorting}
              parentField={props.parentField}
              disableRemoving={props.disableRemoving}
              emptinessLabel={props.emptinessLabel}
            />
          </div>
          {/* <p>a2</p> */}
          <SqattributesCall name={props.historyName}/>
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input">
          <ToManyRelationshipNav
            name={fieldName}
            defaultData={props.defaultData}
          />
          {/* <p>a3</p> */}
          <SqattributesCall name={props.historyName}/>
        </div>
      </div>
    );
  }

  if (relSchema && relatedResourceType && typeof relatedResourceType === "object" && relatedResourceType.length > 1) {
    if (isEditing) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            <EnumToOneRelationship
              name={fieldName}
              value={(fieldValue || {}).data}
              parentResourceType={resource.type}
              resourceTypes={relatedResourceType}
              onChange={(name, value) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: value});
              }}
            />
          </div>
          {/* <p>a4</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    const relatedResourceLabel = fieldValue && fieldValue.data && fieldValue.data.type
      ? t(fieldValue.data.type, {context: fieldName + "_relationshipType"})
      : null;
    return (
      <div {...elemProps}>
        <div className="field-input">
          <label>{relatedResourceLabel || labelText}</label>
          { fieldValue &&
            <ToOneRelationshipLink value={fieldValue.data} /> }
          { (!fieldValue || !fieldValue.data) &&
            <span>{t([`Empty_${fieldName}`, "ui321:Empty"], {context: "label"})}</span> }
          { typeof fieldValue === "string" && fieldValue }{/* recover for error case */}
        </div>
        {/* <p>a5</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (relSchema) {
    if (isEditing) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <ToOneRelationship
              name={fieldName}
              value={(fieldValue || {}).data}
              resourceType={relatedResourceType}
              template={props.template}
              // TODO: titleField 
              onChange={(name, value) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: value});
              }}
            />
          </div>
          {/* <p>a6</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input">
          {label}
          { fieldValue &&
            <ToOneRelationshipLink value={fieldValue.data} template={props.template} /> }
          { (!fieldValue || !fieldValue.data) &&
            <span>{t([`Empty_${fieldName}`, "ui321:Empty"], {context: "label"})}</span> }
          { typeof fieldValue === "string" && fieldValue }{/* recover for error case */}
        </div>
        {/* <p>a7</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (attrSchema && attrSchema.type === "boolean") {
    if (isEditing && props.as === BoolEnum.componentName) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <BoolEnum
              name={fieldName}
              editedValue={props.editedValue}
              onChange={(name, value) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: value});
              }}
              order={props.order}
            />
          </div>
          {/* <p>a8</p> */}
          <SqattributesCall name={props.historyName}/>
        </div>
      );
    }
    if (isEditing) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <Checkbox
              name={fieldName}
              checked={!!fieldValue}
              onChange={() => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: !fieldValue});
              }}
              value="1"
            />
          </div>
          {/* <p>a9</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input">
          {label}
          { fieldValue !== undefined &&
            typeof fieldValue !== "boolean" ? (typeof fieldValue === "string" ? fieldValue : JSON.stringify(fieldValue)) :
            <Checkbox disabled checked={fieldValue} /> }
        </div>
        {/* <p>a10</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (attrSchema && attrSchema.type === "string" && attrSchema.format === "date-time") {
    if (isEditing) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <DateField.DateTimeInput
              value={fieldValue}
              onChange={(date, errors) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: date, inputErrors: errors});
              }}
              inputProps={{name: fieldName}}
            />
            <Icon name="calendar alternate outline" className="input-icon" />
          </div>
          {/* <p>a11</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input">
          {label}
          <DateField.DateTimeString value={fieldValue} />
        </div>
        {/* <p>a12</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (attrSchema && attrSchema.type === "string" && attrSchema.format === "date") {
    if (isEditing) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <DateField.DateInput
              value={fieldValue}
              onChange={(date, errors) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: date, inputErrors: errors});
              }}
              inputProps={{name: fieldName}}
            />
            <Icon name="calendar alternate outline" className="input-icon" />
          </div>
          {/* <p>a13</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input">
          {label}
          <DateField.DateString value={fieldValue} />
        </div>
        {/* <p>a14</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (attrSchemaRaw && attrSchemaRaw.type === "array" && attrSchemaRaw.items && attrSchemaRaw.items.enum) {
    if (isEditing) {
      const itemProps = (value, content) => (
        {key: value, value: value, text: content, content: content}
      );
      const itemContent = value => t(value, {context: fieldName + "_fieldValue"});
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <Dropdown fluid selection
              multiple
              clearable
              name={fieldName}
              value={fieldValue !== undefined && fieldValue !== null ? fieldValue : []}
              options={[
                ...attrSchemaRaw.items.enum.map(val => itemProps(val, itemContent(val))),
              ]}
              onChange={(event, data) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: data.value});
              }}
            />
          </div>
          {/* <p>a15</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input" data-value={fieldValue}>
          {label}
          <div className="field-value">
            <ul className="array-values">
              {fieldValue.map(v =>
                <li key={v} data-value={v}>{t(v, {context: fieldName + "_fieldValue"})}</li>
              )}
            </ul>
          </div>
        </div>
        {/* <p>a16</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (attrSchema && (attrSchemaRaw.enum || attrSchema.enum)) {
    const stateSchema = fieldStateSchema(((resource || {}).meta || {}).stateSchemas || [], fieldName);
    if (isEditing) {
      const itemProps = (value, content) => (
        {key: value, value: value, text: content, content: content}
      );
      const itemContent = value => (
        <span data-value={value}>
          {t(value, {context: fieldName + "_fieldValue"})}
        </span>
      );
      const options = stateSchema
        ? transitionEnum(stateSchema, isNew ? undefined : valueBefore)
          .map(val => itemProps(val, itemContent(val)))
        : [
          ...(attrSchema.default !== undefined && attrSchema.default !== null ? [] : [itemProps(null, "")]),
          ...(attrSchemaRaw.enum || attrSchema.enum).map(val => itemProps(val, itemContent(val))),
        ];
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <Dropdown fluid selection
              name={fieldName}
              value={fieldValue !== undefined && fieldValue !== null ? fieldValue : ""}
              options={options}
              onChange={(event, data) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: data.value});
              }}
            />
            { !!stateSchema &&
              <StateFieldButton fieldName={fieldName} />
            }
          </div>
          {/* <p>a17</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
    return (
      <div {...elemProps}>
        <div className="field-input" data-value={fieldValue}>
          {label}
          <span>{t(fieldValue, {context: fieldName + "_fieldValue"})}</span>
          { !!stateSchema &&
            <StateFieldButton fieldName={fieldName} />
          }
        </div>
        {/* <p>a18</p> */}
        <SqattributesCall name={props.historyName}/>
      </div>
    );
  }

  if (attrSchema) {
    if (isEditing) {
      return (
        <div {...elemProps}>
          <div className="field-input">
            <EditedFieldState isModified={isModified} />
            {label}
            <Input fluid labelPosition="left"
              placeholder={props.placeholder}
              name={fieldName}
              value={fieldValue === undefined || fieldValue === null ? "" : fieldValue}
              onChange={(event, data) => {
                editorDispatch({type: "CHANGE", fieldName: fieldName, value: data.value});
              }}
            />
          </div>
          {/* <p>a19</p> */}
          <SqattributesCall name={props.historyName}/>
          { fieldErrors.length > 0 &&
            <Message error content={fieldErrors.join('; ')} /> }
        </div>
      );
    }
  }

  const stringValue = !fieldValue || typeof fieldValue === "string" ? fieldValue
    : ( typeof fieldValue === "number" && props.locale !== undefined
      ? Number(fieldValue).toLocaleString(props.locale || undefined, {maximumFractionDigits: 10})
      : JSON.stringify(fieldValue) );
  return (
    <div {...elemProps}>
      <div className="field-input">
        {label}
        <span>{stringValue}</span>
        <SqattributesCall name={props.historyName}/>
      </div>

    </div>
  );
}

function useFieldValue(fieldName) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const patchValue = !editor.editing ? undefined :
    ((editor.patch.relationships || {})[fieldName] !== undefined
        ? editor.patch.relationships[fieldName]
        : (editor.patch.attributes || {})[fieldName]);
  const fieldValue = patchValue !== undefined
    ? patchValue
    : (fieldName === "id"
      ? resource.id
      : ((resource.relationships || {})[fieldName] !== undefined
        ? resource.relationships[fieldName]
        : (resource.attributes || {})[fieldName]));
  return fieldValue;
}

function EditedFieldState(props) {
  const {t} = useTranslation("ui321");
  if (!props.isModified) {
    return null;
  }
  return (
    <label className="edited-field-state">
      <Icon name='pencil'
        title={t("Value was modified")}
      />
    </label>
  );
}

function Break() {
  return (
    <div className="flex-break"></div>
  );
}

export {Break, useFieldValue};
export default Field
