/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Leon Nikitin <nlv@lab321.ru>
 */

import React from "react";
import Field from "ui321/fields/Field.js";
import Sqattributes from "./Sqattributes";

function SqattributesDetailLine(props) {
  return (
    <>
      <Field name="accdate" />
      <Field name="val_amount" />
      <Field name="endaccdate" />
    </>
  );
}

export default SqattributesDetailLine;
