import i18n from "i18n.js";
import en_i18n from "./i18n/en.Sqattributes.json";
import ru_i18n from "./i18n/ru.Sqattributes.json";
import FullView from "./FullView.js";

const resourceType = "Sqattributes";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const Sqattributes = {
  resourceType,
  FullView,
  titleField: "name",
  defaultSort: [["refsquid","asc"], ["accdate", "desc"]],
  fieldNames: [
    "parent",
    "name",
    "accdate",
    "val_amount",
    "val_time",
    "val_text",
    "endaccdate",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id"
  ],
  itemViewFields: {
    // "meta": [
    // ],
    "description": [
        "parent",
        "name",
        "accdate",
        "endaccdate"
    ],
    "extra": [
        "date_entered",
        "created_by",
        "date_modified",
        "modified_user_id",
    ]
  },
  creationAccess: true,
};

export default Sqattributes
